import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import ContentWrapper from './content-wrapper'

const Container = styled.footer``

const StyledWrapper = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
`

const Navigation = styled.div`
  display: flex;
  align-items: center;
  height: 8.8rem;
  background: var(--color-primary-3);

  a {
    color: black;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }

  a + a {
    margin-left: 2rem;
  }
`

const Text = styled.div`
  height: 8.8rem;
  background: var(--color-primary-1);
  color: var(--color-secondary-3);
  display: flex;
  align-items: center;
  margin: 0;

  p {
    margin: 0;
  }
`

const SiteFooter = () => {
  const data = useStaticQuery(graphql`
    query Footer {
      allContentfulNavigation(filter: { title: { eq: "Secondary" } }) {
        nodes {
          links {
            ... on ContentfulPage {
              slug
              title
            }
            ... on ContentfulContactAndPressPage {
              slug
              title
            }
          }
        }
      }
    }
  `)

  const {
    allContentfulNavigation: { nodes },
  } = data
  const { links } = nodes[0]

  return (
    <Container>
      <Navigation>
        <ContentWrapper>
          <nav>
            {links.map(({ title, slug }) => (
              <Link key={slug} to={`/${slug}`} activeClassName="active">
                {title}
              </Link>
            ))}
          </nav>
        </ContentWrapper>
      </Navigation>
      <Text>
        <StyledWrapper>
          <p>© Kvarteret Yxan är ett projekt av Stiftelsen Borgerskapets Enkehus och Gubbhus</p>
        </StyledWrapper>
      </Text>
    </Container>
  )
}

export default SiteFooter
