import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import SiteHeader from './site-header'
import SiteFooter from './site-footer'
import Pattern from './pattern'
import CookieBar from './cookie-bar'
import { fadeIn } from '../styles/animations'
import '../styles/global.scss'

const Main = styled.main`
  animation: ${fadeIn} 300ms forwards;
  min-height: calc(100vh - 17.6rem);
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
`

const Layout = ({ showPattern, children }) => {
  const [cookieConsent, setCookieConsent] = useState(true)

  useEffect(() => {
    try {
      const cookieConsented = localStorage.getItem('cookieConsent')

      if (!cookieConsented) {
        setCookieConsent(false)
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  const acceptCookies = () => {
    try {
      localStorage.setItem('cookieConsent', 'yes')
      setCookieConsent(true)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <SiteHeader />

      {showPattern && <Pattern />}

      <Main>{children}</Main>
      <SiteFooter></SiteFooter>
      {!cookieConsent && <CookieBar onAccept={acceptCookies} />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showPattern: PropTypes.bool,
}

export default Layout
