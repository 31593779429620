import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  max-width: var(--site-width);
  padding: 0 var(--site-gutter);
  margin: 0 auto;
`

const ContentWrapper = props => <Container {...props} />

export default ContentWrapper
