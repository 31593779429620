import { useStaticQuery, graphql } from 'gatsby'
import { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import useResizeAware from 'react-resize-aware'
import ContentWrapper from './content-wrapper'

const Header = styled.header`
  position: fixed;
  top: 1.6rem;
  z-index: 999;
  width: 100%;
  color: var(--color-black);

  a {
    color: currentColor;
  }
`

const Container = styled.div`
  position: relative;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 2.4rem;
  align-items: center;
  padding: 1.6rem 3.2rem;
  font-size: 1.6rem;
  line-height: 1;
  box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.02);
  transition: background 200ms;

  ${({ opaque }) =>
    opaque &&
    `
    background: rgba(255, 255, 255, 1);
  `}

  @media (min-width: 48em) {
    border-radius: 10rem;
    display: flex;
    background: rgba(255, 255, 255, 0.85);
  }
`

const Nav = styled(animated.div)`
  height: 0;
  overflow: hidden;

  > div {
    padding-top: 1rem;
    padding-bottom: 1.6rem;
    border-top: 0.1rem solid #d1d2d4;
    position: relative;
    top: 1.3rem;
  }

  a {
    display: block;
    padding: 0.8rem 0;

    &.active {
      font-weight: 500;
    }
  }

  @media (min-width: 48em) {
    display: flex;
    height: auto !important;
    overflow: visible;

    > div {
      padding: 0%;
      border-top: 0;
      position: static;
    }

    a {
      display: inline-block;
      margin-left: 2rem;
      padding: 0;
    }
  }

  @media (min-width: 62.5em) {
    a {
      margin-left: 3.2rem;
    }
  }
`

const ToggleNav = styled.button`
  appearance: none;
  position: absolute;
  right: 2rem;
  top: 0.4rem;
  width: 4rem;
  height: 4rem;
  padding: 0;
  border: 0;
  outline: 0;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 1.7rem;
    height: 0.1rem;
    left: 1.2rem;
    background: currentColor;
    border-radius: 10rem;
    transition: transform 300ms;
  }

  &::before {
    top: 1.6rem;
  }

  &::after {
    bottom: 1.6rem;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    &::before {
      transform:  translate(0, 0.3rem) rotate(45deg);
    }

    &::after {
      transform: translate(0, -0.4rem) rotate(-45deg);
    }
  `}

  @media (min-width: 48em) {
    display: none;
  }
`

const SiteHeader = () => {
  const data = useStaticQuery(graphql`
    query Header {
      allContentfulNavigation(filter: { title: { eq: "Primary" } }) {
        nodes {
          links {
            ... on ContentfulPage {
              slug
              title
            }
            ... on ContentfulContactAndPressPage {
              slug
              title
            }
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const {
    allContentfulNavigation: { nodes },
    site: {
      siteMetadata: { title },
    },
  } = data
  const { links } = nodes[0]

  const [showNav, setShowNav] = useState(false)
  const [resizeListener, sizes] = useResizeAware()

  const spring = useSpring({ height: showNav ? sizes.height : 0 })

  /*
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 0 && !transparent) {
        setTransparent(true)
      } else if (window.scrollY <= 0 && transparent) {
        setTransparent(false)
      }
    }

    document.addEventListener('scroll', onScroll)
    onScroll()

    return () => document.removeEventListener('scroll', onScroll)
  }, []) */

  return (
    <Header>
      <ContentWrapper>
        <Container opaque={showNav}>
          <Link to="/">{title}</Link>

          <Nav style={spring}>
            <div>
              {resizeListener}

              {links.map(({ title, slug }) => (
                <Link key={slug} to={`/${slug}`} activeClassName="active">
                  {title}
                </Link>
              ))}
            </div>
          </Nav>

          <ToggleNav
            isOpen={showNav}
            onClick={() => setShowNav(!showNav)}
            aria-label={showNav ? 'Stäng navigation' : 'Öppna navigation'}
          />
        </Container>
      </ContentWrapper>
    </Header>
  )
}

export default SiteHeader
