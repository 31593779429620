import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  left: 0;
  top: 0;
`

const Inner = styled.div`
  width: 160vw;
  left: -20vw;
  top: 8vw;
  z-index: -1;
  position: relative;

  @media (min-width: 48em) {
    width: 100vw;
    left: 0;
    top: 0;
  }

  @media (min-width: 90em) {
    width: 144rem;
    left: 50%;
    margin-left: -72rem;
  }
`

const Dummy = styled.div`
  padding-top: 40%;
`

const Bar = styled.div`
  width: 8.3%;
  background: white;
  opacity: 0.5;
  position: absolute;
  transform-origin: 50% 0%;
  border-radius: 100rem;
`

const Bar1 = styled(Bar)`
  height: ${49.2 * 2.5}%;
  left: -1.25%;
  top: -${6.46 * 2.5}%;
  transform: rotate(-45deg);
`

const Bar2 = styled(Bar)`
  height: ${30.6 * 2.5}%;
  transform: rotate(45deg);
  left: 55.19%;
  top: ${12.59 * 2.5}%;
`

const Bar3 = styled(Bar)`
  height: ${55.9 * 2.5}%;
  transform: rotate(45deg);
  left: 94.57%;
  top: -${15 * 2.5}%;
`

const Pattern = props => {
  return (
    <Container {...props}>
      <Inner>
        <Dummy />
        <Bar1 />
        <Bar2 />
        <Bar3 />
      </Inner>
    </Container>
  )
}

export default Pattern
