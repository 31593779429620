import PropTypes from 'prop-types'
import styled from 'styled-components'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const Container = styled.div`
  text-align: center;
  position: relative;
  z-index: 2;
`

const Heading = styled.h2`
  font-family: Canela, serif;
  max-width: 42rem;
  margin: 0 auto 0.7em;
  font-size: var(--typography-1);
  line-height: 1;

  @media (min-width: 62.5em) {
    max-width: 58rem;

    ${({ wide }) => wide && `max-width: 86.6rem;`}
  }
`

const Text = styled.div`
  max-width: 50rem;
  margin: 0 auto;

  h2 {
    font-size: var(--typography-3);
    line-height: 1.17;
    margin: 0 0 1em;
  }

  h3 {
    font-size: 1.9rem;
  }
`

const SectionTop = ({ title, text, as, wide, children, ...props }) => (
  <Container {...props}>
    {title && (
      <Heading wide={wide} as={as}>
        {title}
      </Heading>
    )}
    {text && <Text>{renderRichText(text)}</Text>}
    {children && <Text>{children}</Text>}
  </Container>
)

SectionTop.propTypes = {
  title: PropTypes.string,
  text: PropTypes.object,
  as: PropTypes.string,
  wide: PropTypes.bool,
  children: PropTypes.node,
}

export default SectionTop
