import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ContentWrapper from './content-wrapper'

const Container = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  color: #222;
  background: #fbfbfb;
  color: var(--color-black);
  background: var(--color-white);
  padding: 1rem 0;
`

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  align-items: center;
`

const Icon = styled.button`
  appearance: none;
  border: 0;
  padding: 0;
  position: relative;
  width: 4.6rem;
  height: 4.6rem;
  background: #f4f1f0;
  background: var(--color-secondary-3);
  border-radius: 50%;
  margin: 0 2rem 0 0;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 2.2rem;
    height: 0.1rem;
    background: currentColor;
    position: absolute;
    left: 1.2rem;
    top: 50%;
    transition: transform 300ms;
  }

  &::before {
    transform: rotate(135deg);
  }
  &::after {
    transform: rotate(225deg);
  }
`

const Text = styled.p`
  margin: 0;
  font-size: 1.4rem;

  @media (min-width: 62.5em) {
    font-size: 1.6rem;
  }
`

const CookieBar = ({ onAccept }) => {
  return (
    <Container>
      <StyledContentWrapper>
        <div>
          <Icon onClick={onAccept} />
        </div>
        <Text>
          Vi använder cookies för att webbplatsen ska fungera på ett bra sätt. Cookies används
          främst för att analysera vår trafik. Om du inte vill tillåta cookies kan du stänga av dem
          i din webbläsare. <Link to="/cookie-policy">Se vår cookie policy</Link>.
        </Text>
      </StyledContentWrapper>
    </Container>
  )
}

CookieBar.propTypes = {
  onAccept: PropTypes.func.isRequired,
}

export default CookieBar
