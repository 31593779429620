import { keyframes } from 'styled-components'

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

export const fadeInUp = keyframes`
  from { opacity: 0; transform: translate(0, 3rem); }
  to { opacity: 1; transform: translate(0, 0); }
`
